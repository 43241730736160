.toggleoscar {
  background: #fff;
  position: absolute;
  z-index: 1000;
  border-radius: 3px;
  width: 40px;
  border: 1px solid rgba(0, 0, 0, 0.4);
  font-family: "Open Sans", sans-serif;
  right: 8px;
  top: 15pc;
}

@media (max-width: 1073px) {
  .toggleoscar {
    background: #fff;
    position: absolute;
    z-index: 1000;
    border-radius: 3px;
    width: 40px;
    border: 1px solid rgba(0, 0, 0, 0.4);
    font-family: "Open Sans", sans-serif;
    right: 8px;
    top: 15pc;
  }
}

@media (max-height: 800px) {
  .toggleoscar {
    font-size: 0.7rem;
  }
}

.legend h4 {
  margin: 0 0 0.5rem;
  font-weight: bold;
  white-space: pre-wrap;
}

.toggleoscar div span.legend-element-style {
  border-radius: 50%;
  display: inline-block;
  height: 0.5rem;
  margin-right: 0.5rem;
  width: 0.5rem;
  pointer-events: none; /* To avoid bug when clicking on the span */
}

.toggleoscar-info {
  width: 1.1rem;
  height: 1.1rem;
  margin-left: 0.5rem;
  cursor: pointer;
}

.toggleoscar-class-clickable {
  cursor: pointer;
  background-color: gold;
  background-image: url("../../../images/icons/oscar_surface.png");
  background-repeat: round;
  height: 30px;
}
.toggleoscar-class-clickable a {
  color: transparent;
}
.unselected {
  opacity: 0.3;
}

.toggleoscar_class-row {
  display: flex;
  background: white;
  padding: 3px;
}
.toggleoscar-class-clickable a:hover {
  color: transparent;
}

.toggleoscar-class-clickable[data-filter="cl-not-oscar"] {
  color: transparent;
}

.toggleoscar-class-clickable.unselected {
  background-color: white;
  opacity: 100 !important;
}
