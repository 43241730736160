/* App.css */
.App {
  text-align: center;
  padding: 100px;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 450px;
  height: 300px;
  background-color: rgba(0, 0, 0, 0.5);
  justify-content: center;
  align-items: center;
}

.modal {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
}

.modal-content {
  text-align: center; /* Center content horizontally */
}

#model_map {
  /* position: absolute; */
  /* top: 100px; */
  bottom: 0;
  width: 100%;
  height: 300px;
  top: -9px;
  z-index: 100;
}

.modal .marker {
  background-color: blue;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  cursor: pointer;
  border: 0.5px solid white;
}

.modal .marker-start {
  background-color: blue;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  cursor: pointer;
  border: 0.5px solid white;
}
.modal .marker-end {
  background-color: blue;
  width: 12px;
  height: 10px;
  border-radius: 50%;
  cursor: pointer;
  border: 3px solid white;
}
.route_disclaimer {
  text-align: right;
  font-size: 10px;
  padding: 0px;
  width: -moz-fit-content;
  width: fit-content;
  /* float: right; */
  /* position: absolute; */
  right: 20px;
  margin-top: 1px;
  margin-left: 3px;
}
.route_div {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: space-between;
}
