/* RESET */
*,
*::before,
*::after {
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
  color: rgba(0, 0, 0, 0.65);
}

body {
  display: flex;
  font-size: 62.5%;
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

a {
  color: #1890ff;
  text-decoration: none;
}

@media all and (max-width: 1199px) {
  body {
    font-size: 60%;
  }
}

/* Using the following breakpoints
@media all and (max-width: 1199px) { ... } 
@media all and (max-width: 991px) { ... } 
@media all and (max-width: 768px) { ... } 
@media all and (max-width: 575px) { ... }
*/
