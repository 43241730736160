.dbs-row {
  display: flex;
  line-height: 2rem;
  justify-content: center;
}

.dbs-row-item {
  width: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.period-item {
  height: 1rem;
  width: 1rem;
  border-radius: 50%;
  margin: 0;
}

.border-right {
  border-right: 1px solid #ccc;
}

.period-element {
  padding: 0.25rem;
  margin: 0.25rem;
}

.period-item-available {
  background-color: rgb(122, 204, 122);
}

.period-item-missing {
  background-color: rgb(238, 128, 88);
}

@media (max-width: 1410px) {
  .period-item {
    height: 0.5rem;
    width: 0.5rem;
    padding: 0rem;
  }
  .period-element {
    padding: 0;
    margin: 1px;
  }
}
