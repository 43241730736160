.menu-top {
  display: inline-block !important;
}

#menu-about,
#menu-dbstatus {
  float: right;
}

#menu-support {
  float: right;
  color: rgba(0, 0, 0, 0.65);
  border-bottom: 2px solid transparent;
}

.submenu-support {
  background-color: white !important;

  color: rgba(0, 0, 0, 0.65);
}

.submenu-support a {
  color: rgba(0, 0, 0, 0.65);
}

.submenu-support:hover {
  background-color: #e6f7ff !important;
}
