.dbs-footer-item {
  width: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.current-period {
  font-weight: bold;
}

.button-periods {
  border: none;
  outline: none;
  cursor: pointer;
  color: #1890ff;
  background-color: white;
}

.dbs-footer-arrow {
  margin: 3px;
}

@media (max-width: 1000px) {
  .dbs-footer-item {
    width: 33%;
  }
}
