.container-dbs {
  width: 70%;
  margin: 5rem auto;
  font-size: 0.9rem;
}

/* Style tab links */
.tablink {
  float: left;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 14px 16px;
  font-size: 17px;
  width: 50%;
}

.tablink-active {
  color: #1890ff;
  background-color: white;
  border-top: 1.5px solid #1890ff;
}

.tablink-inactive {
  color: #bdbdbd;
  background-color: #f0f0f0;
}

.tablink:hover {
  background-color: #f4f7fb;
}

.dbs-header {
  font-weight: bold;
  width: 100%;
  padding-top: 2rem;
  display: flex;
  flex-direction: column;
}

.dbs-body {
  display: flex;
  flex-direction: column;
}

.spinner-periods {
  margin-top: 2rem !important;
}

.dbs-footer {
  display: flex;
  line-height: 2rem;
  justify-content: center;
  align-items: center;
}

.dbs-nodata {
  text-align: center;
}

@media (max-width: 800px) {
  .container-dbs {
    width: 100%;
    font-size: 0.7rem;
  }
}
