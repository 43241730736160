.presentation {
  width: 70%;
  margin: auto;
  font-size: 0.9rem;
}

.presentation p {
  text-align: justify;
  margin-top: 1rem;
}
