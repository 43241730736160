ul.popup-station-properties {
  list-style: none;
  padding-left: 0;
}

.popup-title-center {
  text-align: center;
  font-weight: bold;
  text-decoration: underline;
}
.popup-value-highlighted {
  font-weight: bold;
  font-style: italic;
}

.mapboxgl-popup-content-big {
  text-align: center;
  height: 18rem;
  width: 36rem;
  overflow-y: auto;
}

.mapboxgl-popup-content-big table {
  font-size: 0.8rem;
}

.ant-table-title {
  font-weight: bold;
  background-color: #f8f4f3;
}
.isDisabled {
  color: currentColor;
  cursor: not-allowed;
  opacity: 0.5;
  text-decoration: none;
}