.dbs-header-item {
  display: flex;
  width: 20%;
  text-align: center;
  justify-content: space-evenly;
}

.list-periods:not(:last-of-type) {
  border-right: 1px solid #ccc;
}

.container-period {
  height: 2rem;
  width: 1rem;
  margin: 0;
}

@media (max-width: 1410px) {
  .container-period {
    font-size: 0.7rem;
  }
}
