.level2 {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.switch-container {
  display: flex;
}

.spinner-level2 {
  margin: auto !important;
}

.title-map {
  padding-left: 1rem;
  margin-top: 2rem;
  font-size: 1.5rem;
}
.alert-no-data {
  margin-top: 2rem !important;
}
.subtitle-map {
  font-size: medium;
  padding-left: 1rem;
  margin-bottom: 0.5rem;
}
@media (max-width: 775px) {
  .title-map {
    font-size: 1.2rem;
  }
}
