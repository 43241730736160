#root {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  background-color: white;
}

.level1 {
  width: 100%;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
}

.wdqms-content {
  display: flex;
  flex-grow: 1;
  width: 82%;
  margin: auto;
}

@media (max-width: 1310px) {
  .wdqms-content {
    width: 90%;
  }
}

@media (max-width: 1199px) {
  .wdqms-content {
    width: 100%;
  }
}

header,
main,
footer {
  flex-shrink: 0;
}
