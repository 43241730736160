.suggestions {
  position: absolute;
  top: 50px;
  left: 8px;
  z-index: 1;
  background-color: white;
  box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  /* width: 150%; */
  border-top-width: 0;
  list-style: none;
  margin-top: 0;
  max-height: 143px;
  overflow-y: auto;
  padding-left: 0;
  margin-top: 10px;
  user-select: none;
}

.suggestions li {
  padding: 0.5rem;
}

.suggestion-active,
.suggestions li:hover {
  background-color: #e6f7ff;
  color: #1890ff;
  cursor: pointer;
  font-weight: 700;
}
