.ts-container {
  display: flex;
  flex-wrap: wrap;
}

.link-container {
  display: flex;
  /* width: 50%; */
  justify-content: space-around;
}

a#link-chart {
  margin-right: 3px;
}