.legend {
  background-color: #fff;
  border-radius: 3px;
  bottom: 2rem;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  padding: 0.5rem;
  position: absolute;
  left: 0.5rem;
  z-index: 1;
  opacity: 0.9;
}

@media (max-width: 1073px) {
  .legend {
    font-size: 0.7rem;
  }
}

@media (max-height: 800px) {
  .legend {
    font-size: 0.7rem;
  }
}

.legend h4 {
  margin: 0 0 0.5rem;
  font-weight: bold;
  white-space: pre-wrap;
}

.legend div span.legend-element-style {
  border-radius: 50%;
  display: inline-block;
  height: 0.5rem;
  margin-right: 0.5rem;
  width: 0.5rem;
  pointer-events: none; /* To avoid bug when clicking on the span */
}

.legend-info {
  width: 1.1rem;
  height: 1.1rem;
  margin-left: 0.5rem;
  cursor: pointer;
}

.legend-class-clickable {
  cursor: pointer;
}

.unselected {
  opacity: 0.3;
}

.legend_class-row {
  display: flex;
}
