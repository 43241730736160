/* !important is needed to overwrite: width: 520px; */
.ant-modal {
  height: 50vh !important;
  width: 50vw !important;
  animation-duration: 0s !important; /*To see if it solves the issue with the chart that sometimes is very small */
}

.ant-modal-content {
  height: 100%;
  width: 100%;
}

.ant-modal-header {
  height: 10%;
}

.ant-modal-title p {
  font-size: 0.9rem !important;
}

.ant-modal-body {
  height: 78%;
  background-color: #f4f7fb;
  padding-top: 10px;
}

.ant-modal-footer {
  height: 12%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.chart-title {
  color: #1f3349;
  font-weight: bold;
  text-align: center;
  padding-bottom: 10px;
}

#chart-container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

#chart-station {
  height: 100%;
  width: 100%;
}

.spinner-chart {
  position: absolute;
  top: 50%;
  left: 50%;
}
.hidden {
  display: none !important;
}

div.tooltip {
  z-index: 1000;
  opacity: 0;
  background-color: white;
  border-width: 1px;
  border-radius: 4px;
  font-size: 0.8rem;
}
.tooltip-title {
  width: 100%;
  text-align: center;
  font-weight: bold;
  background-color: #1f3349;
  color: white;
  padding: 2px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
.tooltip-body {
  padding: 5px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}
.tooltip-item {
  display: flex;
  align-items: center;
}
.tooltip-body > div:not(:last-of-type) {
  border-bottom: 1px solid #f4f7fb;
}
.tooltip-item-icon {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  margin-right: 0.8rem;
}

.tooltip-item-line {
  height: 100%;
  margin-right: 0.4rem;
}
.tooltip-item-label {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.tooltip-item-complex {
  display: flex;
  flex-direction: column;
}

.tooltip-item-complex > div:not(:last-of-type) {
  border-bottom: 2px solid #f4f7fb;
}

.tooltip-item-complex-title {
  width: 100%;
  padding: 2px;
  margin: 1px;
  text-align: center;
  border-radius: 5%;
  font-weight: bold;
  color: #1f3349;
}

.tooltip-item-complex-elem {
  display: flex;
  justify-content: space-between;
}

.tooltip-item-complex-elem-part:first-of-type {
  padding-right: 6px;
}

@media (max-height: 850px) {
  .ant-modal {
    height: 70vh !important;
  }
}

@media (max-height: 600px) {
  .ant-modal {
    height: 80vh !important;
  }
  .ant-modal-title p {
    font-size: 0.7rem !important;
  }
}

@media (max-width: 1199px) {
  .ant-modal {
    width: 70vh !important;
  }
}
