#map-container {
  display: flex;
  height: 75%;
}

@media (max-width: 1199px) {
  #map-container {
    height: 80%;
  }
}

@media (max-width: 768px) {
  #map-container {
    height: 95%;
  }
}

div#map {
  display: flex;
  flex: 1;
}

hr {
  border: 0;
  height: 0;
  margin: 2px 0px 2px 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}

div#popup-content {
  margin-top: 1rem;
}

a.link-oscar:after {
  content: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAQElEQVR42qXKwQkAIAxDUUdxtO6/RBQkQZvSi8I/pL4BoGw/XPkh4XigPmsUgh0626AjRsgxHTkUThsG2T/sIlzdTsp52kSS1wAAAABJRU5ErkJggg==);
  margin: 0 3px 0 5px;
}

.control-icon {
  width: 20px;
  height: 20px;
}

.mapboxgl-ctrl-attrib {
  margin: 0 !important;
  border-radius: 0 !important;
  background-color: hsla(0, 0%, 100%, 0.5) !important;
}

.mapboxgl-ctrl-attrib:after {
  content: none !important;
}

@media (max-width: 1073px) {
  .mapboxgl-ctrl-attrib {
    font-size: 0.7rem;
  }
}

@media (max-height: 800px) {
  .mapboxgl-ctrl-attrib {
    font-size: 0.7rem;
  }
}

#menu_toggle {
  background: #fff;
  position: absolute;
  z-index: 1000;
  border-radius: 3px;
  width: 40px;
  border: 1px solid rgba(0, 0, 0, 0.4);
  font-family: "Open Sans", sans-serif;
  right: 8px;
  top: 10pc;
}

#menu_toggle a {
  font-size: 10px;
  color: #404040;
  display: block;
  height: 30px;
  margin: 0;
  padding: 0;
  /* padding: 10px; */
  text-decoration: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.25);
  text-align: center;
  text-indent: -9999px;
  margin: 3px;
}

#menu_toggle a.Buoy {
  background-image: url("../../images/icons/buoy-icon.png");
  background-repeat: round;
}

#menu_toggle a.Ship {
  background-image: url("../../images/icons/ship-icon.png");
  background-repeat: round;
}

#menu_toggle a:last-child {
  border: none;
}


#menu_toggle a.active {
  background-color: gold;
  color: #ffffff;
  text-transform: uppercase;
  font-weight: bold;
}
