.ctrl-search {
  background-color: #f4f7fb;
  position: absolute;
  top: 8px;
  left: 8px;
  z-index: 1;
  width: 35px;
  height: 35px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  border-radius: 50%;
  transition: 0.3s;
  box-shadow: 0 4px 4px -1px black;
}

.ctrl-search-open {
  margin-top: 10px;
  margin-left: 10px;
  background-color: #f4f7fb;
  position: absolute;
  z-index: 4;
  height: fit-content;
  cursor: pointer;
  transition: 0.3s;
  display: inline-block;
  box-shadow: 0 4px 4px -1px black;
  border-radius: 0.5rem;
}

.inside-filter-open {
  position: relative;
  float: left;
}

.inside-filter-closed {
  display: none;
}

#filter-input {
  border: 1px solid gray;
  border-radius: 4px;
  width: auto;
  float: left;
  position: relative;
  height: 27px;
  margin-top: 5px;
  margin-left: 5px;
  margin-right: 5px;
  margin-bottom: 5px;
}

.btn-close-search-ctrl {
  border: none;
  height: 24px;
  width: 24px;
  border-radius: 50%;
  cursor: pointer;
  background-color: #eaebf3;
  color: #5b7897;
  margin-left: 4px;
  margin-top: 5px;
  margin-right: 15px;
}

.bootstrap-demo .dropdown-content {
  position: absolute;
  z-index: 100;
  text-align: left;
  background-color: white;
  padding: 5px;
  /* margin-left: 41px; */
  width: 230px;
  /* max-height: 280px; */
  max-height: 280px;
  overflow: overlay;
}

.react-dropdown-tree-select .dropdown {
  position: relative;
  display: table;
}

.react-dropdown-tree-select .dropdown .dropdown-trigger.arrow {
  cursor: pointer;
  width: 230px;
}

.react-dropdown-tree-select .dropdown .dropdown-trigger {
  margin-top: 10px;
  /* margin-top: 4px; */
  padding: 1px;
  line-height: 8px;
  max-height: 200px;
  display: inline-block;
  overflow: auto;
  border: 1px solid gray;
  background-color: white;
}

.tag-list {
  display: inline;
  padding: 0;
  margin: 0;
}

.tag-item {
  display: inline-block;
  /* margin: 4px; */
}

.tag-item .search {
  border: none;
  /* border-bottom: 1px solid #ccc; */
  outline: none;
  height: 23px;
}

.tag {
  background-color: #f4f4f4;
  border: 1px solid #e9e9e9;
  padding: 2px 0 2px 2px;
  border-radius: 2px;
  display: inline-block;
  margin-right: 1px;
  margin-left: 1px;
  /* display: none; */
}

.react-dropdown-tree-select {
  float: left;
  position: relative;
  max-width: 384px;
  display: none;
}

.ctrl-search-open img {
  float: left;
  position: relative;
  margin-top: 9px;
  margin-left: 9px;
  margin-right: 9px;
}
button.tag-remove {
  border: none;
  cursor: pointer;
}

.bootstrap-demo .toggle {
  /* font: normal normal normal 12px/1 FontAwesome; */
  /* font: icon; */
  color: #555;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='29' height='29' viewBox='0 0 29 29' xmlns='http://www.w3.org/2000/svg' fill='%23333'%3E%3Cpath d='M14.5 8.5c-.75 0-1.5.75-1.5 1.5v3h-3c-.75 0-1.5.75-1.5 1.5S9.25 16 10 16h3v3c0 .75.75 1.5 1.5 1.5S16 19.75 16 19v-3h3c.75 0 1.5-.75 1.5-1.5S19.75 13 19 13h-3v-3c0-.75-.75-1.5-1.5-1.5z'/%3E%3C/svg%3E");
  background-position: 50%;
  background-repeat: no-repeat;
  position: relative;
  top: -3px;
  /* left: -1px; */
  background-size: 19px;
  margin-right: 4px;
}

span.node-label {
  margin-left: 5px;
}

.mapboxgl-ctrl button .mapboxgl-ctrl-icon {
  display: block;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: 50%;
}

/* bootstrap-demo is a custom classname to increases the specificity of our styles. It can be anything. 
 * The idea is that it is easy to extend/override builtin styles with very little effort.
 */
 .bootstrap-demo .dropdown-trigger {
  border-radius: 0.25rem;
}

.bootstrap-demo .dropdown-trigger > span:after {
  font-size: 12px;
  color: #555;
}

.bootstrap-demo .toggle.collapsed::after {
  content: "\f067";
}

.bootstrap-demo .toggle.expanded::after {
  content: "\f068";
}

.bootstrap-demo .root {
  padding: 0px;
  margin: 0px;
}