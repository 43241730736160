.homepage {
  display: flex;
  flex-direction: column;
  margin: auto;
  width: 80%;
}

.homepage-part-1 {
  /* margin: 6rem auto 3rem; */
  margin: 6rem auto 0rem;
  font-size: 0.9rem;
}

.homepage-part-1 h2 {
  margin-bottom: 2rem;
  font-weight: bold;
}

.homepage-part-1 h3 {
  margin-bottom: 3rem;
}

.homepage-part-1 p {
  text-align: justify;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.homepage-part-2 {
  /* display: flex; */
  justify-content: center;
  font-size: 0.9rem;
}

.module-container {
  width: 100%;
  /* display: flex; */
  justify-content: space-between;
  /* align-items: flex-end;
  align-content: center;
  flex-wrap: wrap; */
}

.data-module {
  border: 1px solid #e8e8e8;
  /* display: flex;
  flex-direction: column; */
  width: 48%;
  margin-bottom: 3rem;
  border-radius: 8px;
  /* flex-direction: column; */
}

.data-module.marine {
  display: table;
  margin: auto;
  margin-bottom: 2rem;
  margin-right: 2rem;
  float: left;
}

.data-module.gbon {
  /* display: table; */
  margin: auto;
  margin-bottom: 2rem;
  clear: right;
  /* float: left; */
}

.data-module.nwp {
  float: left;
  clear: both;
  margin-right: 2rem;
}

.data-module.gcos {
  float: left;
  /* margin-left: 2rem; */
}

.data-module-title {
  padding: 1rem;
  border-bottom: 1px solid #e8e8e8;
  background-color: #e8e8e8;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.data-model-newfeature {
  float: right;
  color: white;
  background-color: #198754;
  padding: 1px;
  border: 1px solid white;
  padding-right: 7px;
  padding-left: 7px;
  font-weight: bold;
  border-radius: 50% 20% / 10% 40%;
  animation: blinker 1s linear infinite;
}

.data-module-body {
  display: flex;
  justify-content: space-evenly;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.ant-card {
  width: 11rem;
  margin-bottom: 1rem !important;
}

.ant-card-meta-title {
  white-space: pre-wrap !important;
}

.ant-card-cover {
  display: flex;
  height: 11rem;
  text-align: center;
  align-items: center;
  background-color: #f9f9f9;
}

.ant-card-cover img {
  height: 11rem;
  object-fit: cover;
}

.ant-card-body {
  text-align: center;
}

@media all and (max-width: 1673px) {
  .data-module-title {
    font-size: 0.8rem;
  }
  .nwp .ant-card {
    width: min-content !important;
  }
}

@media all and (max-width: 1501px) {
  .data-module-title {
    font-size: 0.7rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }

}

@media all and (max-width: 1086px) {
  .module-container {
    flex-direction: column;
    align-items: center;
  }
  .data-module {
    width: 70%;
  }
  .data-module-title {
    padding: 1rem;
    font-size: 0.9rem;
  }
  .data-module.nwp,
  .data-module.data-module.gcos,
  .data-module.marine,
  .data-module.gbon {
    display: table;
    margin: auto;
    margin-bottom: 2rem;
    float: none;
  }
}

@media all and (max-width: 750px) {
  .data-module {
    width: 80%;
  }

  .data-module-body {
    flex-direction: column;
    align-items: center;
  }
  .ant-card {
    margin-bottom: 1rem;
    width: 12rem;
  }
}

@media all and (max-width: 310px) {
  .ant-card {
    width: 10rem;
  }
}
