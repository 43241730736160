.gsn-availability-container {
  display: flex;
  flex-flow: row-reverse wrap;
  justify-content: space-around;
}

.gsn-availability-item {
  display: flex;
  flex-direction: column;
  border-radius: 10%;
  padding: 5px;
  margin: 5px;
}

.gsn-availability-row {
  text-align: center;
}

@media (max-width: 1350px) {
  .gsn-availability-container {
    justify-content: flex-start;
  }
}

@media (max-width: 500px) {
  .gsn-availability-row {
    font-size: 0.7rem;
  }
}
