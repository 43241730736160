.title-baseline {
  padding: 3px 5px;
}

input[type="radio"] {
  display: none;
}

input[type="radio"]:checked + label {
  background-color: #1890ff;
  color: #ffffff;
  font-weight: bold;
}

.options-baseline {
  display: flex;
  background-color: #ffffff;
  border-radius: 15px;
  margin-left: 5px;
  border: 1px solid #f0f0f0;
}

.options-baseline label {
  border-radius: 15px;
  padding: 3px 5px;
  /* margin-left: 5px; */
  color: rgba(0, 0, 0, 0.5);
  text-align: center;
  /* width: 50%; */
  /* float: left; */
}

.baseline-active {
  cursor: pointer;
}

.baseline-inactive {
  opacity: 0.5;
  pointer-events: none;
  cursor: not-allowed;
}
